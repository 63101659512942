<template>
    <Settings></Settings>
</template>

<script>
    import Settings from "@/views/administration/management/settings/components/settings";

    export default {
        name: "index",
        components: {Settings}
    }
</script>
